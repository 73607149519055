import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { DeleteItemModalService } from '../../delete-item-modal/delete-item-modal.service';
import { SliderService } from '../../../../services/slider.service';

@Component({
  selector: 'app-slider-detail',
  templateUrl: './slider-detail.component.html',
  styleUrls: ['./slider-detail.component.scss']
})
export class SliderDetailComponent implements OnInit {
  slide;
  id: number;
  constructor(private sliderService: SliderService,
              private idSharedService: IdSharedService,
              private route: ActivatedRoute,
              private router: Router,
              private deleteItemModalService: DeleteItemModalService) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.id = await this.idSharedService.getIdSelectedItem();
          if(this.id == null) return;
          this.slide = await this.sliderService.getSingleSlide(this.id);
          console.log(this.slide);
        }
      )
  }

  onEditSlide() {
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteSlide() {
    this.deleteItemModalService.confirm('Usuwasz slide!', 'Czy na pewno chcesz usunąc ten slide?')
     .then((confirmed) => {
       if(confirmed) {
         this.sliderService.deleteSlide(this.id);
         this.slide = undefined;
       }
     })
     .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
   }

}
