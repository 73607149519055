import { Component, OnInit } from '@angular/core';
import { IdSharedService } from '../../../services/id-shared.service';

@Component({
  selector: 'app-chains',
  templateUrl: './chains.component.html',
  styleUrls: ['./chains.component.scss']
})
export class ChainsComponent implements OnInit {

  constructor(private idSharedService: IdSharedService) { }

  ngOnInit() {
  }

  selectedChain(chain) {
    this.idSharedService.id = chain.id;
  }

}
