import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-about-app-edit',
  templateUrl: './about-app-edit.component.html',
  styleUrls: ['./about-app-edit.component.scss']
})
export class AboutAppEditComponent implements OnInit {
  aboutAppContent;

  dataToUpdate: IdataToUpdate = {
    type: null,
    content: null
  };

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    this.technicalDataService.getTextData('about-app').then(async res => {
      this.aboutAppContent = new FormControl(await res.content);
    });
  }

  async onSubmit() {
    this.dataToUpdate.type = "aboutApp";
    this.dataToUpdate.content = this.aboutAppContent.value;
    await this.technicalDataService.update(this.dataToUpdate);
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}

export interface IdataToUpdate {
  type: string;
  content: FormControl;
}
