import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-instruction',
  templateUrl: './basic-instruction.component.html',
  styleUrls: ['./basic-instruction.component.scss']
})
export class BasicInstructionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
