import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { ResponseHandlingService } from '../services/response-handling.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadLeafletFileService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService) { }

  leafletChanged = new Subject();
  onGetLeafletFileName = new EventEmitter();

  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    const req = new HttpRequest('POST', this.globals.apiUrl + 'leaflet/uploadLeaflet', formdata, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  pushPhotoToStorage(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    const req = new HttpRequest('POST', this.globals.apiUrl + 'leaflet/uploadPhoto', formdata, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  addLeaflet(leafletToAdded, filename) {
    leafletToAdded.filename = filename;
    return this.http.post(this.globals.apiUrl + 'leaflet', leafletToAdded)
                    .toPromise()
                    .then(() => {
                        this.responseHandlingService.handleData
                        this.leafletChanged.next();
                    })
                    .catch(this.responseHandlingService.handleError);
  }

  deleteLeaflet(filename: string) {
    let fileNameToDelete = { filename: filename };
    return this.http.post(this.globals.apiUrl + 'leaflet/deleteLeaflet', fileNameToDelete)
      .toPromise()
      .then(() => {
        this.responseHandlingService.handleData;
        this.leafletChanged.next();
      })
      .catch(this.responseHandlingService.handleError);
  }
 
  getFileName() {
    return this.http.get(this.globals.apiUrl + 'leaflet/getLeafletFileName')
      .toPromise()
      .then(this.responseHandlingService.handleData)
      .catch(this.responseHandlingService.handleError);          
  }

  getLeaflets() {
    return this.http.get(this.globals.apiUrl + 'leaflet/B')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }
  
}
