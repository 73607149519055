import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { PricePromotionsService } from '../../../../services/price-promotions.service';
import { IdSharedService } from '../../../../services/id-shared.service';
import { DeleteItemModalService } from '../../delete-item-modal/delete-item-modal.service';
import { CurrentElementChainService } from '../../../../services/current-element-chain.service';

@Component({
  selector: 'app-price-promotions-detail',
  templateUrl: './price-promotions-detail.component.html',
  styleUrls: ['./price-promotions-detail.component.scss']
})
export class PricePromotionsDetailComponent implements OnInit {
  pricePromotion;
  id: number;
  constructor(private pricePromotionsService: PricePromotionsService,
              private idSharedService: IdSharedService,
              private route: ActivatedRoute,
              private router: Router,
              private deleteItemModalService: DeleteItemModalService,
              private currentElementChainService: CurrentElementChainService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.id = await this.idSharedService.getIdSelectedItem();
          if(this.id == null) return;
          this.pricePromotion = await this.pricePromotionsService.getSinglePromotion(this.id);
        }
      )
  }

  onEditPromotion() {
    this.currentElementChainService.chainName = this.pricePromotion.chain;
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeletePromotion() {
   this.deleteItemModalService.confirm('Usuwasz promocję!', 'Czy na pewno chcesz usunąc promocję: "' + this.pricePromotion.productName + '" ?')
    .then((confirmed) => {
      if(confirmed) {
        this.pricePromotionsService.deletePromotion(this.id);
        this.pricePromotion = undefined;
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
