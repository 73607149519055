import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-push-notification-modal',
  templateUrl: './confirm-push-notification-modal.component.html',
  styleUrls: ['./confirm-push-notification-modal.component.scss']
})
export class ConfirmPushNotificationModalComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() to: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal) {
    
  }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
