import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { DiscountCodesService } from '../../../../services/discount-codes.service';


@Component({
  selector: 'app-discount-codes-edit',
  templateUrl: './discount-codes-edit.component.html',
  styleUrls: ['./discount-codes-edit.component.scss']
})
export class DiscountCodesEditComponent implements OnInit {
  editMode = false;
  discountCodesForm: FormGroup;
  loaded: boolean = false;

  discountCodeId: number;
  productTitle: string;
  productName: string;
  productWeight: string;
  productUnit: string;
  productBarcode: string;
  productPromotionalCode: string;
  promotionalPrice: string;
  promotionalUnitPrice: string;
  regularPrice: string;
  regularUnitPrice: string;
  rabateValue: string;
  expirationDate: string;
  additionalCondition: string;
  imagePath: string;
  publishDate: string;

  dataToUpdate: IdataToUpdate = {
    id: null,
    formValue: null
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private idSharedService: IdSharedService,
              private discountCodesService: DiscountCodesService) {      
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {

          this.editMode = params['id'] != null;

          if (this.editMode) {
            this.discountCodeId = this.idSharedService.getIdSelectedItem();
            await this.discountCodesService.getSingleDiscountCode(this.discountCodeId).then(discountCode => {
              this.productTitle = discountCode.title;
              this.productName = discountCode.productName;
              this.productWeight = discountCode.weight;
              this.productUnit = discountCode.unit;
              this.productBarcode = discountCode.barcode;
              this.productPromotionalCode = discountCode.promotionalCode;
              this.promotionalPrice = discountCode.promotionalPrice;
              this.promotionalUnitPrice = discountCode.promotionalUnitPrice;
              this.regularPrice = discountCode.regularPrice;
              this.regularUnitPrice = discountCode.regularUnitPrice;
              this.rabateValue = discountCode.rabateValue;
              this.expirationDate = discountCode.expirationDate;
              this.additionalCondition = discountCode.additionalCondition;
              this.imagePath = discountCode.imagePath;
              this.publishDate = new Date(discountCode.publishDate).toISOString().split('T')[0];
              this.initForm();
              this.loaded = true;
            });
          } else {
            this.initForm();
            this.loaded = true;
          }
          
        }
      );
  }

  async onSubmit() {
    if (this.editMode) {
      this.dataToUpdate.id = this.discountCodeId;
      this.dataToUpdate.formValue = this.discountCodesForm.value;
      await this.discountCodesService.updateDiscountCode(this.dataToUpdate);
    } else {
      await this.discountCodesService.addDiscountCode(this.discountCodesForm.value);
    }
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.discountCodesForm = new FormGroup({
      'title': new FormControl(this.productTitle),
      'product': new FormControl(this.productName, Validators.required),
      'weight': new FormControl(this.productWeight, Validators.required),
      'unit': new FormControl(this.productUnit, Validators.required),
      'barcode': new FormControl(this.productBarcode),
      'promotionalCode': new FormControl(this.productPromotionalCode, Validators.required),
      'promotionalPrice': new FormControl(this.promotionalPrice, Validators.required),
      'promotionalUnitPrice': new FormControl(this.promotionalUnitPrice),
      'regularPrice': new FormControl(this.regularPrice),
      'regularUnitPrice': new FormControl(this.regularUnitPrice),
      'rabateValue': new FormControl(this.rabateValue, Validators.required),
      'expirationDate': new FormControl(this.expirationDate, Validators.required),
      'additionalCondition': new FormControl(this.additionalCondition),
      'imagePath': new FormControl(this.imagePath, Validators.required),
      'publishDate': new FormControl(this.publishDate, Validators.required)
    });
  }

}

export interface IdataToUpdate {
  id: number;
  formValue: FormGroup;
}
