import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  private validToken: boolean = false;

  constructor(private http: HttpClient,
              private globals: Globals) { }

  init() {
    if(localStorage.getItem('usernameLoggedIn')) {
      return this.http.get(this.globals.apiUrl + 'user/tokenValidity')
                .toPromise()
                .then(data => {
                  this.validToken = true;
                })
                .catch(err => {
                  this.validToken = false;
                })
    }
    return null;
  }

  public isStoredTokenValid() {
    return this.validToken;
  }

}
