import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service';

@Component({
  selector: 'app-version-control-detail',
  templateUrl: './version-control-detail.component.html',
  styleUrls: ['./version-control-detail.component.scss']
})
export class VersionControlDetailComponent implements OnInit {
  currentAndroidVersion: string;
  currentIOSVersion: string;
  importantMessage: string;

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

    async ngOnInit() {
      this.currentAndroidVersion = await this.technicalDataService.getTextData('current-android-version');
      this.currentIOSVersion = await this.technicalDataService.getTextData('current-ios-version');
      this.importantMessage = await this.technicalDataService.getTextData('important-message');
    }
  
    onEditItem() {
      this.router.navigate(['edit'], {relativeTo: this.route});
    }

}
