import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UploadLeafletFileService } from '../../../../services/upload-leaflet-file.service';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-leaflet-form-upload',
  templateUrl: './leaflet-form-upload.component.html',
  styleUrls: ['./leaflet-form-upload.component.scss']
})
export class LeafletFormUploadComponent implements OnInit {

  uploadedFileStatus: boolean;
  selectedFile: FileList;
  currentFileUpload: File;
  leafletForm: FormGroup;
  progress: { percentage: number } = { percentage: 0 };

  leafletTitle: string;
  expirationDate: string;
  publishDate: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private uploadService: UploadLeafletFileService) { }

  ngOnInit() {
    this.initForm();
  }

  selectFile(event) {
    this.selectedFile = event.target.files;
  }

  upload() {
    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFile.item(0);
    this.uploadService.pushFileToStorage(this.currentFileUpload).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress.percentage = Math.round(100 * event.loaded / event.total);
      }
    });
    this.selectedFile = undefined;
    this.uploadService.addLeaflet(this.leafletForm.value, this.currentFileUpload.name);
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.leafletForm = new FormGroup({
      'title': new FormControl(this.leafletTitle, Validators.required),
      'expirationDate': new FormControl(this.expirationDate, Validators.required),
      'publishDate': new FormControl(this.publishDate, Validators.required)
    });
  }

}
