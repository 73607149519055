export class News {
    public chain: string;
    public title: string;
    public description: string;
    public shortDesc: string;
    public tags: string;
    public imagePath: string;
    public publishDate: Date;
  
    constructor(chain: string,
                title: string, 
                description: string, 
                shortDesc: string, 
                tags: string, 
                imgPath: string,
                publishDate: Date) 
    {

        this.chain = chain;
        this.title = title;
        this.description = description;
        this.shortDesc = shortDesc;
        this.tags = tags;
        this.imagePath = imgPath;
        this.publishDate = publishDate;
    }
  }