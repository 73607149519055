import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Slides } from '../models/slides.model';
import { ResponseHandlingService } from '../services/response-handling.service';
import { IdSharedService } from '../services/id-shared.service';

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService,
              private idSharedService: IdSharedService) { }

  slidesChanged = new Subject();

  getSlides(): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'slider')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }

  getSingleSlide(index: number): Promise<Slides> {
    let id: number = index;
    return this.http.get<Slides>(this.globals.apiUrl + 'slider/' + id)
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }

  updateSlide(slideToUpdate) {
    this.idSharedService.id = slideToUpdate.formValue.id;
    return this.http.post(this.globals.apiUrl + 'slider/edit', slideToUpdate)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.slidesChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  deleteSlide(slideIdToDelete) {
      let slideToDelete = { id: slideIdToDelete }
      return this.http.post(this.globals.apiUrl + 'slider/delete', slideToDelete)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData;
                      this.slidesChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }

  addSlide(slideToAdded) {
      return this.http.post(this.globals.apiUrl + 'slider', slideToAdded)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData;
                      this.slidesChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }
}
