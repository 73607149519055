import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';
import { IdSharedService } from './id-shared.service';
import { Chains } from '../models/chains.model';

@Injectable({
  providedIn: 'root'
})
export class ChainsService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService,
              private idSharedService: IdSharedService) { }

  chainsChanged = new Subject();

  getChains(): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'chains')
                .toPromise()
                .then(this.responseHandlingService.handleData)
                .catch(this.responseHandlingService.handleError)
  }

  getSingleChain(index: number): Promise<Chains> {
    let id: number = index;
    return this.http.get<Chains>(this.globals.apiUrl + 'chains/' + id)
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }

  updatePromotion(promotionToUpdate) {
    this.idSharedService.id = promotionToUpdate.formValue.id;
    return this.http.post(this.globals.apiUrl + 'chains/edit', promotionToUpdate)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.chainsChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  deletePromotion(promotionIdToDelete) {
      let promotionToDelete = { id: promotionIdToDelete }
      return this.http.post(this.globals.apiUrl + 'chains/delete', promotionToDelete)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData;
                      this.chainsChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }

  addPromotion(promotionToAdded) {
      return this.http.post(this.globals.apiUrl + 'chains', promotionToAdded)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData;
                      this.chainsChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }
  
}
