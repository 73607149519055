import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { DeleteItemModalService } from '../../delete-item-modal/delete-item-modal.service';
import { ChainsService } from '../../../../services/chains.service';

@Component({
  selector: 'app-chains-detail',
  templateUrl: './chains-detail.component.html',
  styleUrls: ['./chains-detail.component.scss']
})
export class ChainsDetailComponent implements OnInit {
  chain;
  id: number;
  constructor(private chainsService: ChainsService,
              private idSharedService: IdSharedService,
              private route: ActivatedRoute,
              private router: Router,
              private deleteItemModalService: DeleteItemModalService) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.id = await this.idSharedService.getIdSelectedItem();
          if(this.id == null) return;
          this.chain = await this.chainsService.getSingleChain(this.id);
        }
      )
  }

  onEditChain() {
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteChain() {
    this.deleteItemModalService.confirm('Usuwasz promocję!', 'Czy na pewno chcesz usunąc promocję: "' + this.chain.chainShortName + '" ?')
     .then((confirmed) => {
       if(confirmed) {
         this.chainsService.deletePromotion(this.id);
         this.chain = undefined;
         this.router.navigate(['../'], {relativeTo: this.route});
       }
     })
     .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
   }

}
