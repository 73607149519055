import { Component } from '@angular/core';
import { UserAuthenticationService } from './services/user-authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private router: Router) {
    this.skipLoginPageIfTokenValid();
  }

  private skipLoginPageIfTokenValid() {
    if (this.userAuthenticationService.isTokenValid()) {
      this.router.navigate(['/panel']);
    }
  }
}
