import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadLeafletFileService } from 'src/app/services/upload-leaflet-file.service';

@Component({
  selector: 'app-leaflets-item',
  templateUrl: './leaflets-item.component.html',
  styleUrls: ['./leaflets-item.component.scss']
})
export class LeafletsItemComponent implements OnInit {
  @Input() index: number;
  @Input() leaflet;
  @Output() leafletSelected = new EventEmitter<void>();
  constructor(private uploadService: UploadLeafletFileService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
  }

  onSelected() {
    this.router.navigate(['preview'], {relativeTo: this.route}).then(() => {
      this.uploadService.onGetLeafletFileName.emit(this.leaflet.filename);
    });
  }

  onDeleteLeaflet() {
    this.uploadService.deleteLeaflet(this.leaflet.filename);
  }

}
