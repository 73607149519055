import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-privacy-policy-edit',
  templateUrl: './privacy-policy-edit.component.html',
  styleUrls: ['./privacy-policy-edit.component.scss']
})
export class PrivacyPolicyEditComponent implements OnInit {
  privacyPolicyContent;

  dataToUpdate: IdataToUpdate = {
    type: null,
    content: null
  };

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    this.technicalDataService.getTextData('privacy-policy').then(async res => {
      this.privacyPolicyContent = new FormControl(await res.content);
    });
  }

  async onSubmit() {
    this.dataToUpdate.type = "privacyPolicy";
    this.dataToUpdate.content = this.privacyPolicyContent.value;
    await this.technicalDataService.update(this.dataToUpdate);
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}

export interface IdataToUpdate {
  type: string;
  content: FormControl;
}
