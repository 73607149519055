import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { News } from '../news.model';
import { NewsService } from '../news.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
  @Output() newsWasSelected = new EventEmitter<News>();
  news: News[];
  subscription: Subscription;
  
  constructor(private newsService: NewsService,
              private router: Router,
              private route: ActivatedRoute) {    
              }

  ngOnInit() {
    this.getNews();
    this.subscription = this.newsService.newsChanged
      .subscribe(() => {
        this.getNews();
      }
    )
  }

  async getNews() {
    this.news = await this.newsService.getNews();
  }

  onNewsSelected(news: News) {
    this.newsWasSelected.emit(news);
   }

   onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }
}
