import { Component, OnInit } from '@angular/core';
import { IdSharedService } from '../../../services/id-shared.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {

  constructor(private idSharedService: IdSharedService) { }

  ngOnInit() {
  }

  selectedItem(tutorialSlide) {
    this.idSharedService.id = tutorialSlide.id;
  }

}
