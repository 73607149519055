import { Component, OnInit } from '@angular/core';
import { IdSharedService } from '../../../services/id-shared.service';

@Component({
  selector: 'app-price-promotions',
  templateUrl: './price-promotions.component.html',
  styleUrls: ['./price-promotions.component.scss']
})
export class PricePromotionsComponent implements OnInit {

  constructor(private idSharedService: IdSharedService) { }

  ngOnInit() {
  }

  selectedPromotion(promotion) {
    this.idSharedService.id = promotion.id;
  }

}
