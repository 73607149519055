import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { SliderService } from '../../../../services/slider.service';

@Component({
  selector: 'app-slider-edit',
  templateUrl: './slider-edit.component.html',
  styleUrls: ['./slider-edit.component.scss']
})
export class SliderEditComponent implements OnInit {

  editMode = false;
  slidesForm: FormGroup;
  loaded: boolean = false;

  slideId: number;
  chain: string;
  imagePath: string;
  publishDate: string;

  dataToUpdate: IdataToUpdate = {
    id: null,
    formValue: null
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private idSharedService: IdSharedService,
              private sliderService: SliderService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.editMode = params['id'] != null;
          
          if(this.editMode) {
            this.slideId = this.idSharedService.getIdSelectedItem();
            await this.sliderService.getSingleSlide(this.slideId).then(slide => {
              this.chain = slide.chain;
              this.imagePath = slide.imagePath;
              this.publishDate = new Date(slide.publishDate).toISOString().split('T')[0];
            })
            this.initForm();
            this.loaded = true;
          } else {
            this.initForm();
            this.loaded = true;
          }
        }
      )
  }

  async onSubmit() {
    if (this.editMode) {
      this.dataToUpdate.id = this.slideId;
      this.dataToUpdate.formValue = this.slidesForm.value;
      await this.sliderService.updateSlide(this.dataToUpdate);
    } else {
      await this.sliderService.addSlide(this.slidesForm.value);
    }
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.slidesForm = new FormGroup({
      'chain': new FormControl(this.chain),
      'imagePath': new FormControl(this.imagePath, Validators.required),
      'publishDate': new FormControl(this.publishDate, Validators.required)
    });
  }
}

export interface IdataToUpdate {
  id: number;
  formValue: FormGroup;
}
