import { Component, OnInit } from '@angular/core';
import { UploadLeafletFileService } from '../../../services/upload-leaflet-file.service';
import { HttpEventType } from '@angular/common/http';
import { Globals } from '../../../globals';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  selectedFile: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  copyUrlValue = "";

  constructor(private uploadService: UploadLeafletFileService,
              private globals: Globals) { }

  ngOnInit() {
  }
  selectFile(event) {
    this.selectedFile = event.target.files;
  }
  
  upload() {
    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFile.item(0);
    this.uploadService.pushPhotoToStorage(this.currentFileUpload).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress.percentage = Math.round(100 * event.loaded / event.total);
      }
    });
    this.selectedFile = undefined;
    this.copyUrlValue = this.globals.hostname + this.currentFileUpload.name;
  }


}
