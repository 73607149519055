import { Component, OnInit } from '@angular/core';
import { IdSharedService } from '../../../services/id-shared.service';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss']
})
export class ShopsComponent implements OnInit {

  constructor(private idSharedService: IdSharedService) { }

  ngOnInit() {
  }

  selectedShop(shop) {
    this.idSharedService.id = shop.id;
  }

}
