import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DiscountCodesComponent } from "./components/panel-container/discount-codes/discount-codes.component";
import { DiscountCodesDetailComponent } from "./components/panel-container/discount-codes/discount-codes-detail/discount-codes-detail.component";
import { DiscountCodesEditComponent } from "./components/panel-container/discount-codes/discount-codes-edit/discount-codes-edit.component";
import { PromotionalLeafletComponent } from "./components/panel-container/promotional-leaflet/promotional-leaflet.component";
import { NewsComponent } from "./components/panel-container/news/news.component";
import { PricePromotionsComponent } from "./components/panel-container/price-promotions/price-promotions.component";
import { LoyaltyProgramComponent } from "./components/panel-container/loyalty-program/loyalty-program.component";
import { DetailStarterComponent } from "./components/panel-container/detail-starter/detail-starter.component";
import { NewsDetailComponent } from "./components/panel-container/news/news-detail/news-detail.component";
import { NewsEditComponent } from "./components/panel-container/news/news-edit/news-edit.component";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { PanelContainerComponent } from "./components/panel-container/panel-container.component";
import { PricePromotionsDetailComponent } from './components/panel-container/price-promotions/price-promotions-detail/price-promotions-detail.component';
import { PricePromotionsEditComponent } from './components/panel-container/price-promotions/price-promotions-edit/price-promotions-edit.component';
import { ChainsComponent } from './components/panel-container/chains/chains.component';
import { ChainsDetailComponent } from './components/panel-container/chains/chains-detail/chains-detail.component';
import { ChainsEditComponent } from './components/panel-container/chains/chains-edit/chains-edit.component';
import { LeafletFormUploadComponent } from './components/panel-container/promotional-leaflet/leaflet-form-upload/leaflet-form-upload.component';
import { PdfviewerContainerComponent } from './components/panel-container/promotional-leaflet/pdfviewer-container/pdfviewer-container.component';
import { VersionControlComponent } from './components/panel-container/version-control/version-control.component';
import { ShopsComponent } from './components/panel-container/shops/shops.component';
import { ShopsDetailComponent } from './components/panel-container/shops/shops-detail/shops-detail.component';
import { ShopsEditComponent } from './components/panel-container/shops/shops-edit/shops-edit.component';

import { AuthGuard } from './helpers/auth.guard';
import { CanEditElementGuard } from './helpers/can-edit-element.guard';

import { Role } from './models/role.model';
import { AboutAppComponent } from "./components/panel-container/about-app/about-app.component";
import { AboutAppEditComponent } from "./components/panel-container/about-app/about-app-edit/about-app-edit.component";
import { AboutAppDetailComponent } from "./components/panel-container/about-app/about-app-detail/about-app-detail.component";
import { RulesComponent } from "./components/panel-container/rules/rules.component";
import { RulesDetailComponent } from "./components/panel-container/rules/rules-detail/rules-detail.component";
import { RulesEditComponent } from "./components/panel-container/rules/rules-edit/rules-edit.component";
import { PrivacyPolicyComponent } from "./components/panel-container/privacy-policy/privacy-policy.component";
import { PrivacyPolicyDetailComponent } from "./components/panel-container/privacy-policy/privacy-policy-detail/privacy-policy-detail.component";
import { PrivacyPolicyEditComponent } from "./components/panel-container/privacy-policy/privacy-policy-edit/privacy-policy-edit.component";
import { FileUploaderComponent } from "./components/panel-container/file-uploader/file-uploader.component";
import { SliderComponent } from "./components/panel-container/slider/slider.component";
import { SliderEditComponent } from "./components/panel-container/slider/slider-edit/slider-edit.component";
import { SliderDetailComponent } from "./components/panel-container/slider/slider-detail/slider-detail.component";
import { VersionControlDetailComponent } from "./components/panel-container/version-control/version-control-detail/version-control-detail.component";
import { VersionControlEditComponent } from "./components/panel-container/version-control/version-control-edit/version-control-edit.component";
import { NextLeafletStartDateComponent } from "./components/panel-container/promotional-leaflet/next-leaflet-start-date/next-leaflet-start-date.component";
import { NextLeafletStartDateEditComponent } from "./components/panel-container/promotional-leaflet/next-leaflet-start-date-edit/next-leaflet-start-date-edit.component";
import { HelpComponent } from "./components/panel-container/help/help.component";
import { BasicInstructionComponent } from "./components/panel-container/help/basic-instruction/basic-instruction.component";
import { PushNotificationsInstructionComponent } from "./components/panel-container/help/push-notifications-instruction/push-notifications-instruction.component";
import { TutorialComponent } from "./components/panel-container/tutorial/tutorial.component";
import { TutorialEditComponent } from "./components/panel-container/tutorial/tutorial-edit/tutorial-edit.component";
import { TutorialDetailComponent } from "./components/panel-container/tutorial/tutorial-detail/tutorial-detail.component";
import { PushNotificationComponent } from "./components/panel-container/push-notification/push-notification.component";

const appRoutes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full'},
    { path: 'panel', component: PanelContainerComponent, canActivate: [AuthGuard], children: [
        { path: 'help', component: HelpComponent, canActivate: [AuthGuard], children: [
            { path: '', component: DetailStarterComponent },
            { path: 'basic-instruction', component: BasicInstructionComponent },
            { path: 'push-not-instruction', component: PushNotificationsInstructionComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.EditorPlus] } },
        ] },
        { path: 'slider', component: SliderComponent,canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: SliderEditComponent },
            { path: ':id', component: SliderDetailComponent },
            { path: ':id/edit', component: SliderEditComponent },
        ] },
        { path: 'news', component: NewsComponent,canActivate: [AuthGuard], children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: NewsEditComponent },
            { path: ':id', component: NewsDetailComponent},
            { path: ':id/edit', component: NewsEditComponent, canActivate: [CanEditElementGuard] },
        ] },
        { path: 'discount-codes', component: DiscountCodesComponent, canActivate: [AuthGuard], children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: DiscountCodesEditComponent },
            { path: ':id', component: DiscountCodesDetailComponent },
            { path: ':id/edit', component: DiscountCodesEditComponent, canActivate: [CanEditElementGuard] },
        ] },
        { path: 'promotional-leaflet', component: PromotionalLeafletComponent, canActivate: [AuthGuard], children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: LeafletFormUploadComponent },
            { path: 'preview', component: PdfviewerContainerComponent },
            { path: 'next-leaflet-start-date', component: NextLeafletStartDateComponent },
            { path: 'next-leaflet-start-date-edit', component: NextLeafletStartDateEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } }
        ] }, 
        { path: 'price-promotions', component: PricePromotionsComponent, canActivate: [AuthGuard], children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: PricePromotionsEditComponent },
            { path: ':id', component: PricePromotionsDetailComponent },
            { path: ':id/edit', component: PricePromotionsEditComponent, canActivate: [CanEditElementGuard] },
        ] },
        { path: 'loyalty-program', component: LoyaltyProgramComponent },
        { path: 'chains', component: ChainsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: ChainsEditComponent },
            { path: ':id', component: ChainsDetailComponent },
            { path: ':id/edit', component: ChainsEditComponent},
        ] },
        { path: 'shops', component: ShopsComponent, canActivate: [AuthGuard], children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: ShopsEditComponent },
            { path: ':id', component: ShopsDetailComponent },
            { path: ':id/edit', component: ShopsEditComponent, canActivate: [CanEditElementGuard] },
        ] },
        { path: 'tutorial', component: TutorialComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: DetailStarterComponent },
            { path: 'new', component: TutorialEditComponent },
            { path: ':id', component: TutorialDetailComponent },
            { path: ':id/edit', component: TutorialEditComponent },
        ] },
        { path: 'push', component: PushNotificationComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.EditorPlus] }},
        { path: 'upload', component: FileUploaderComponent, canActivate: [AuthGuard] },
        { path: 'about-app', component: AboutAppComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: AboutAppDetailComponent },
            { path: 'edit', component: AboutAppEditComponent },
        ] },
        { path: 'rules', component: RulesComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: RulesDetailComponent },
            { path: 'edit', component: RulesEditComponent },
        ] },
        { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: PrivacyPolicyDetailComponent },
            { path: 'edit', component: PrivacyPolicyEditComponent },
        ] },
        { path: 'version-control', component: VersionControlComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: VersionControlDetailComponent },
            { path: 'edit', component: VersionControlEditComponent },
        ] },
    ] },
    { path: 'login', component: SignInComponent}
]

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {

}