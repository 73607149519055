import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';
import { IdSharedService } from './id-shared.service';
import { Tutorial } from '../models/tutorial.model';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService,
              private idSharedService: IdSharedService) { }

  tutorialChanged = new Subject();

  getSlides(): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'tutorial')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }

  getSingleSlide(index: number): Promise<Tutorial> {
    let id: number = index;
    return this.http.get<Tutorial>(this.globals.apiUrl + 'tutorial/' + id)
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }

  updateSlide(slideToUpdate) {
    this.idSharedService.id = slideToUpdate.formValue.id;
    return this.http.post(this.globals.apiUrl + 'tutorial/edit', slideToUpdate)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData,
                    this.tutorialChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  deleteSlide(tutorialSlideIdToDelete) {
      let slideToDelete = { id: tutorialSlideIdToDelete }
      return this.http.post(this.globals.apiUrl + 'tutorial/delete', slideToDelete)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData;
                      this.tutorialChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }

  addSlide(slideToAdded) {
      return this.http.post(this.globals.apiUrl + 'tutorial', slideToAdded)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData,
                      this.tutorialChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }
}
