export class Slides {
    public chain: string;
    public imagePath: string;
    public publishDate: Date;
  
    constructor(chain: string,
                imagePath: string,
                publishDate: Date) 
    {
        this.chain = chain;
        this.imagePath = imagePath;
        this.publishDate = publishDate;
    }
  }