import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-push-notifications-instruction',
  templateUrl: './push-notifications-instruction.component.html',
  styleUrls: ['./push-notifications-instruction.component.scss']
})
export class PushNotificationsInstructionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
