import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { ShopsService } from '../../../../services/shops.service';

@Component({
  selector: 'app-shops-edit',
  templateUrl: './shops-edit.component.html',
  styleUrls: ['./shops-edit.component.scss']
})
export class ShopsEditComponent implements OnInit {
  editMode = false;
  shopsForm: FormGroup;
  loaded: boolean = false;

  shopId: number;
  chainFullName: string;
  shopName: string;
  postcode: string;
  city: string;
  street: string;
  openingHours: string;
  phone: string;
  email: string;
  website: string;
  lat: string;
  lng: string;
  imagePath: string;
  publishDate: string;

  dataToUpdate: IdataToUpdate = {
    id: null,
    formValue: null
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private idSharedService: IdSharedService,
              private shopsService: ShopsService) {      
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {

          this.editMode = params['id'] != null;

          if (this.editMode) {
            this.shopId = this.idSharedService.getIdSelectedItem();
            await this.shopsService.getSingleShop(this.shopId).then(shop => {
              this.chainFullName = shop.chainFullName;
              this.shopName = shop.shopName;
              this.postcode = shop.postcode;
              this.city = shop.city;
              this.street = shop.street;
              this.openingHours = shop.openingHours;
              this.phone = shop.phone;
              this.email = shop.email;
              this.website = shop.website;
              this.lat = shop.lat;
              this.lng = shop.lng;
              this.imagePath = shop.imagePath;
              this.publishDate = new Date(shop.publishDate).toISOString().split('T')[0];
              this.initForm();
              this.loaded = true;
            });
          } else {
            this.initForm();
            this.loaded = true;
          }
          
        }
      );
  }

  async onSubmit() {
    if (this.editMode) {
      this.dataToUpdate.id = this.shopId;
      this.dataToUpdate.formValue = this.shopsForm.value;
      await this.shopsService.updateShop(this.dataToUpdate);
    } else {
      await this.shopsService.addShop(this.shopsForm.value);
    }
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.shopsForm = new FormGroup({
      'chainFullName': new FormControl(this.chainFullName, Validators.required),
      'shopName': new FormControl(this.shopName, Validators.required),
      'postcode': new FormControl(this.postcode, Validators.required),
      'city': new FormControl(this.city, Validators.required),
      'street': new FormControl(this.street, Validators.required),
      'openingHours': new FormControl(this.openingHours, Validators.required),
      'phone': new FormControl(this.phone, Validators.required),
      'email': new FormControl(this.email),
      'website': new FormControl(this.website),
      'lat': new FormControl(this.lat, Validators.required),
      'lng': new FormControl(this.lng, Validators.required),
      'imagePath': new FormControl(this.imagePath, Validators.required),
      'publishDate': new FormControl(this.publishDate, Validators.required)
    });
  }

}

export interface IdataToUpdate {
  id: number;
  formValue: FormGroup;
}
