import { Component, OnInit } from '@angular/core';
import { IdSharedService } from '../../../services/id-shared.service';

@Component({
  selector: 'app-discount-codes',
  templateUrl: './discount-codes.component.html',
  styleUrls: ['./discount-codes.component.scss']
})
export class DiscountCodesComponent implements OnInit {

  constructor(private idSharedService: IdSharedService) { }

  ngOnInit() {
  }

  selectedDiscountCode(discountCode) {
    this.idSharedService.id = discountCode.id;
  }

}
