import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { UserAuthenticationService } from '../services/user-authentication.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private userAuthenticationService: UserAuthenticationService) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.userAuthenticationService.isAuthenticated()) {
            if(route.data.roles) {
                let requestedUserData = await this.userAuthenticationService.currentUserRole();
                if (route.data.roles && route.data.roles.indexOf(requestedUserData['userRole']) === -1) {
                    alert('Nie masz uprawnień do podglądu tego elementu.')
                    return false;
                }
                else return true;
            } else {
                return true;
            }
        } else {
            this.userAuthenticationService.logout();
            return false;
        }
    }
}