import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service'

@Component({
  selector: 'app-privacy-policy-detail',
  templateUrl: './privacy-policy-detail.component.html',
  styleUrls: ['./privacy-policy-detail.component.scss']
})
export class PrivacyPolicyDetailComponent implements OnInit {
  privacyPolicyContent: string;

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

    async ngOnInit() {
      this.privacyPolicyContent = await this.technicalDataService.getTextData('privacy-policy');
    }
  
    onEditItem() {
      this.router.navigate(['edit'], {relativeTo: this.route});
    }

}
