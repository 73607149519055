import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TutorialService } from '../../../../services/tutorial.service';
import { IdSharedService } from '../../../../services/id-shared.service';
import { DeleteItemModalService } from '../../delete-item-modal/delete-item-modal.service';

@Component({
  selector: 'app-tutorial-detail',
  templateUrl: './tutorial-detail.component.html',
  styleUrls: ['./tutorial-detail.component.scss']
})
export class TutorialDetailComponent implements OnInit {
  tutorialSlide;
  id: number;
  constructor(private tutorialService: TutorialService,
              private idSharedService: IdSharedService,
              private route: ActivatedRoute,
              private router: Router,
              private deleteItemModalService: DeleteItemModalService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.id = await this.idSharedService.getIdSelectedItem();
          if(this.id == null) return;
          this.tutorialSlide = await this.tutorialService.getSingleSlide(this.id);
        }
      )
  }

  onEditSlide() {
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteSlide() {
   this.deleteItemModalService.confirm('Usuwasz slajd tutoriala!', 'Czy na pewno chcesz usunąc slajd: "' + this.tutorialSlide.title + '" ?')
    .then((confirmed) => {
      if(confirmed) {
        this.tutorialService.deleteSlide(this.id);
        this.tutorialSlide = undefined;
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
