import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { PricePromotionsService } from '../../../../services/price-promotions.service';

@Component({
  selector: 'app-price-promotions-edit',
  templateUrl: './price-promotions-edit.component.html',
  styleUrls: ['./price-promotions-edit.component.scss']
})
export class PricePromotionsEditComponent implements OnInit {
  editMode = false;
  pricePromotionsForm: FormGroup;
  loaded: boolean = false;

  pricePromotionId: number;
  productTitle: string;
  productName: string;
  productWeight: string;
  productUnit: string;
  productBarcode: string;
  promotionalPrice: string;
  promotionalUnitPrice: string;
  regularPrice: string;
  regularUnitPrice: string;
  expirationDate: string;
  additionalCondition: string;
  imagePath: string;
  publishDate: string;

  dataToUpdate: IdataToUpdate = {
    id: null,
    formValue: null
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private idSharedService: IdSharedService,
              private pricePromotionsService: PricePromotionsService) {      
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {

          this.editMode = params['id'] != null;

          if (this.editMode) {
            this.pricePromotionId = this.idSharedService.getIdSelectedItem();
            await this.pricePromotionsService.getSinglePromotion(this.pricePromotionId).then(pricePromotion => {
              this.productTitle = pricePromotion.title;
              this.productName = pricePromotion.productName;
              this.productWeight = pricePromotion.weight;
              this.productUnit = pricePromotion.unit;
              this.productBarcode = pricePromotion.barcode;
              this.promotionalPrice = pricePromotion.promotionalPrice;
              this.promotionalUnitPrice = pricePromotion.promotionalUnitPrice;
              this.regularPrice = pricePromotion.regularPrice;
              this.regularUnitPrice = pricePromotion.regularUnitPrice;
              this.expirationDate = pricePromotion.expirationDate;
              this.additionalCondition = pricePromotion.additionalCondition;
              this.imagePath = pricePromotion.imagePath;
              this.publishDate = new Date(pricePromotion.publishDate).toISOString().split('T')[0];
              this.initForm();
              this.loaded = true;
            });
          } else {
            this.initForm();
            this.loaded = true;
          }
          
        }
      );
  }

  async onSubmit() {
    if (this.editMode) {
      this.dataToUpdate.id = this.pricePromotionId;
      this.dataToUpdate.formValue = this.pricePromotionsForm.value;
      await this.pricePromotionsService.updatePromotion(this.dataToUpdate);
    } else {
      await this.pricePromotionsService.addPromotion(this.pricePromotionsForm.value);
    }
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.pricePromotionsForm = new FormGroup({
      'title': new FormControl(this.productTitle),
      'product': new FormControl(this.productName, Validators.required),
      'weight': new FormControl(this.productWeight, Validators.required),
      'unit': new FormControl(this.productUnit, Validators.required),
      'barcode': new FormControl(this.productBarcode),
      'promotionalPrice': new FormControl(this.promotionalPrice, Validators.required),
      'promotionalUnitPrice': new FormControl(this.promotionalUnitPrice),
      'regularPrice': new FormControl(this.regularPrice),
      'regularUnitPrice': new FormControl(this.regularUnitPrice),
      'expirationDate': new FormControl(this.expirationDate, Validators.required),
      'additionalCondition': new FormControl(this.additionalCondition),
      'imagePath': new FormControl(this.imagePath, Validators.required),
      'publishDate': new FormControl(this.publishDate, Validators.required)
    });
  }

}

export interface IdataToUpdate {
  id: number;
  formValue: FormGroup;
}
