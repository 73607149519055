import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service'

@Component({
  selector: 'app-about-app-detail',
  templateUrl: './about-app-detail.component.html',
  styleUrls: ['./about-app-detail.component.scss']
})
export class AboutAppDetailComponent implements OnInit {
  aboutAppContent: string;

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

    async ngOnInit() {
      this.aboutAppContent = await this.technicalDataService.getTextData('about-app');
    }
  
    onEditItem() {
      this.router.navigate(['edit'], {relativeTo: this.route});
    }

}
