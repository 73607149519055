import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { ChainsService } from '../../../../services/chains.service';

@Component({
  selector: 'app-chains-edit',
  templateUrl: './chains-edit.component.html',
  styleUrls: ['./chains-edit.component.scss']
})
export class ChainsEditComponent implements OnInit {
  editMode = false;
  chainsForm: FormGroup;
  loaded: boolean = false;

  chainsId: number;
  chain: string;
  chainFullName: string;
  chainShortName: string;
  logoPath: string;
  publishDate: string;

  dataToUpdate: IdataToUpdate = {
    id: null,
    formValue: null
  };

  constructor(private route: ActivatedRoute,
    private router: Router,
    private idSharedService: IdSharedService,
    private chainsService: ChainsService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.editMode = params['id'] != null;
          
          if(this.editMode) {
            this.chainsId = this.idSharedService.getIdSelectedItem();
            await this.chainsService.getSingleChain(this.chainsId).then(chain => {
              this.chain = chain.chain;
              this.chainFullName = chain.chainFullName;
              this.chainShortName = chain.chainShortName;
              this.logoPath = chain.logoPath;
              this.publishDate = new Date(chain.publishDate).toISOString().split('T')[0];
            })
            this.initForm();
            this.loaded = true;
          } else {
            this.initForm();
            this.loaded = true;
          }
        }
      )
  }

  async onSubmit() {
    if (this.editMode) {
      this.dataToUpdate.id = this.chainsId;
      this.dataToUpdate.formValue = this.chainsForm.value;
      await this.chainsService.updatePromotion(this.dataToUpdate);
    } else {
      await this.chainsService.addPromotion(this.chainsForm.value);
    }
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.chainsForm = new FormGroup({
      'chain': new FormControl(this.chain, Validators.required),
      'chainFullName': new FormControl(this.chainFullName, Validators.required),
      'chainShortName': new FormControl(this.chainShortName, Validators.required),
      'logoPath': new FormControl(this.logoPath, Validators.required),
      'publishDate': new FormControl(this.publishDate, Validators.required)
    });
  }

}

export interface IdataToUpdate {
  id: number;
  formValue: FormGroup;
}
