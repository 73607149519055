import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-tutorial-item',
  templateUrl: './tutorial-item.component.html',
  styleUrls: ['./tutorial-item.component.scss']
})
export class TutorialItemComponent implements OnInit {
  @Input() slide;
  @Output() tutorialSlideSelected = new EventEmitter<void>();
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

  onSelected() {
    this.tutorialSlideSelected.emit();
  }

}
