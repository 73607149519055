import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';
import { News } from './news.model';
import { ResponseHandlingService } from '../../../services/response-handling.service';
import { IdSharedService } from '../../../services/id-shared.service';
import { TagsArrayToStringService } from '../../../services/tags-array-to-string.service';
import { map, take } from 'rxjs/operators';
//import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class NewsService {
    constructor(private http: HttpClient,
                private globals: Globals,
                private responseHandlingService: ResponseHandlingService,
                private idSharedService: IdSharedService,
                private tagsArrayToStringService: TagsArrayToStringService) {}

    newsChanged = new Subject();

    getNews(): Promise<any>{
        return this.http.get(this.globals.apiUrl + 'news/B')
                   .toPromise()
                   .then(this.responseHandlingService.handleData)
                   .catch(this.responseHandlingService.handleError)
    }

    getSingleNews(index: number): Promise<News> {

        let id: number = index;
        return this.http.get<News>(this.globals.apiUrl + 'news/' + id)
                   .toPromise()
                   .then(this.responseHandlingService.handleData)
                   .catch(this.responseHandlingService.handleError);
    }

    updateNews(newsToUpdate) {
        newsToUpdate.formValue.tags = this.tagsArrayToStringService.convert(newsToUpdate.formValue.tags);
        this.idSharedService.id = newsToUpdate.formValue.id;
        return this.http.post(this.globals.apiUrl + 'news/edit', newsToUpdate)
                    .toPromise()
                    .then(() => {
                        this.responseHandlingService.handleData,
                        this.newsChanged.next();
                    })
                    .catch(this.responseHandlingService.handleError);
    }

    deleteNews(newsIdToDelete) {
        let newsToDelete = { id: newsIdToDelete }
        return this.http.post(this.globals.apiUrl + 'news/delete', newsToDelete)
                    .toPromise()
                    .then(() => {
                        this.responseHandlingService.handleData;
                        this.newsChanged.next();
                    })
                    .catch(this.responseHandlingService.handleError);
    }

    addNews(newsToAdded) {
        newsToAdded.tags = this.tagsArrayToStringService.convert(newsToAdded.tags);
        return this.http.post(this.globals.apiUrl + 'news', newsToAdded)
                    .toPromise()
                    .then(() => {
                        this.responseHandlingService.handleData,
                        this.newsChanged.next();
                    })
                    .catch(this.responseHandlingService.handleError);
    }

    /*storeNews() {
        console.log("storuje newsa");
        this.http.post('http://localhost:3001/api/news' , this.news)
          .subscribe(
            (recipes) => {
              console.log(recipes);
            }
          );     
    }*/
}