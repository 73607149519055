import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PricePromotionsService } from '../../../../services/price-promotions.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-price-promotions-list',
  templateUrl: './price-promotions-list.component.html',
  styleUrls: ['./price-promotions-list.component.scss']
})
export class PricePromotionsListComponent implements OnInit {
  @Output() pricePromotionWasSelected = new EventEmitter<void>();
  pricePromotions;
  subscription: Subscription;

  constructor(private pricePromotionsService: PricePromotionsService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.getPromotions();
    this.subscription = this.pricePromotionsService.promotionsChanged
      .subscribe(() => {
        this.getPromotions();
      }
    )
  }

  async getPromotions() {
    this.pricePromotions = await this.pricePromotionsService.getPromotions();
  }

  onPromotionSelected(pricePromotion) {
    this.pricePromotionWasSelected.emit(pricePromotion);
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
