import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdSharedService {

  id: number = null;

   getIdSelectedItem() {
    return this.id;
   }



}
