import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChainsService } from 'src/app/services/chains.service';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { UserAuthenticationService } from '../../../services/user-authentication.service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
  pushForm: FormGroup;

  pushTitle: string;
  pushBody: string;
  chains = [];
  selectedChain;
  pushNavigateUrlParam: string;
  isEditorPlusRoleUser: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private chainsService: ChainsService,
              private pushNotificationService: PushNotificationService,
              private userAuthenticationService: UserAuthenticationService) { }

  ngOnInit() {
    this.initForm();
    this.getChains();
    
  }

  onSubmit() {
    this.pushNotificationService.showPushConfirmationModal(this.pushForm.value.title, this.pushForm.value.body, this.pushForm.value.to, this.pushForm.value.navigateUrlParam)
      .then((confirmed) => {
        if(confirmed) {
         this.pushNotificationService.sendPushNotification(this.pushForm.value);
         alert('Powiadomienie zostało wysłane.')
         this.router.navigate(['../'], {relativeTo: this.route});
       }
      })
     .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  async getChains() {
    let requestedUserData = await this.userAuthenticationService.currentUserRole();
    if(requestedUserData['userRole'] == 'editor+') {
      this.isEditorPlusRoleUser = true;
      let userChain = await this.userAuthenticationService.currentUserChain();
      this.chains.push({ chain: userChain['userChain'] })
    } else {
      this.isEditorPlusRoleUser = false;
      this.chains = await this.chainsService.getChains();
    };
    return this.chains;
  }

  private async initForm() {
    this.pushForm = new FormGroup({
      'title': new FormControl(this.pushTitle, Validators.required),
      'body': new FormControl(this.pushBody, Validators.required),
      'to': new FormControl(this.selectedChain, Validators.required),
      'navigateUrlParam': new FormControl(this.pushNavigateUrlParam, Validators.required),
    });
  }

}
