import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DiscountCodesService } from '../../../../services/discount-codes.service';

@Component({
  selector: 'app-discount-codes-list',
  templateUrl: './discount-codes-list.component.html',
  styleUrls: ['./discount-codes-list.component.scss']
})
export class DiscountCodesListComponent implements OnInit {
  @Output() discountCodeWasSelected = new EventEmitter<void>();
  discountCodes;
  subscription: Subscription;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private discountCodesService: DiscountCodesService ) { }

  ngOnInit() {
    this.getDiscountCodes();
    this.subscription = this.discountCodesService.discountCodesChanged
      .subscribe(() => {
        this.getDiscountCodes();
      }
    )
  }

  async getDiscountCodes() {
    this.discountCodes = await this.discountCodesService.getDiscountCodes();
  }

  onDiscountCodeSelected(discountCode) {
    this.discountCodeWasSelected.emit(discountCode);
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
