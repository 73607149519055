import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-chains-item',
  templateUrl: './chains-item.component.html',
  styleUrls: ['./chains-item.component.scss']
})
export class ChainsItemComponent implements OnInit {
  @Input() index: number;
  @Input() chain;
  @Output() chainSelected = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
  }

  onSelected() {
    this.chainSelected.emit();
  }

}
