import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadLeafletFileService } from '../../../../services/upload-leaflet-file.service';

@Component({
  selector: 'app-leaflet-details-upload',
  templateUrl: './leaflet-details-upload.component.html',
  styleUrls: ['./leaflet-details-upload.component.scss']
})
export class LeafletDetailsUploadComponent implements OnInit {

  leafletFileName = null;
  isFileUploaded: boolean = false;

  constructor(private uploadService: UploadLeafletFileService,
              private router: Router,
              private route: ActivatedRoute) {

              }

  ngOnInit() {
    this.getLeafletFileName();
  }

  async getLeafletFileName() {
    await this.uploadService.getFileName().then((data) => {
      this.leafletFileName = data[0];
    });
  }

  onDeleteLeaflet() {
    this.uploadService.deleteLeaflet(this.leafletFileName);
    this.isFileUploaded = false;
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
