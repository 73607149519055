import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentElementChainService {

  //constructor() { }
  chainName: string;

  getCurrentDisplayedElementChainName() {
    return this.chainName;
   }

}
