import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Slides } from '../../../../models/slides.model';

@Component({
  selector: 'app-slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss']
})
export class SliderItemComponent implements OnInit {
  @Input() slide: Slides;
  @Output() slideSelected = new EventEmitter<void>();
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }
  
  onSelected() {
    this.slideSelected.emit();
  }

}
