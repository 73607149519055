import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-rules-edit',
  templateUrl: './rules-edit.component.html',
  styleUrls: ['./rules-edit.component.scss']
})
export class RulesEditComponent implements OnInit {
  rulesContent;

  dataToUpdate: IdataToUpdate = {
    type: null,
    content: null
  };

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    this.technicalDataService.getTextData('rules').then(async res => {
      this.rulesContent = new FormControl(await res.content);
    });
  }

  async onSubmit() {
    this.dataToUpdate.type = "rules";
    this.dataToUpdate.content = this.rulesContent.value;
    await this.technicalDataService.update(this.dataToUpdate);
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}

export interface IdataToUpdate {
  type: string;
  content: FormControl;
}
