import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Slides } from '../../../../models/slides.model';
import { SliderService } from '../../../../services/slider.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-slider-list',
  templateUrl: './slider-list.component.html',
  styleUrls: ['./slider-list.component.scss']
})
export class SliderListComponent implements OnInit {
  @Output() slideWasSelected = new EventEmitter<Slides>();
  slides: Slides[];
  subscription: Subscription;

  constructor(private sliderService: SliderService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.getSlides();
  }

  async getSlides() {
    this.slides = await this.sliderService.getSlides();
    this.subscription = this.sliderService.slidesChanged
      .subscribe(() => {
        this.getSlides();
      }
    )
  }

  onSlideSelected(slide: Slides) {
    this.slideWasSelected.emit(slide);
   }

   onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
