import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadLeafletFileService } from '../../../../services/upload-leaflet-file.service'
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-leaflets-list',
  templateUrl: './leaflets-list.component.html',
  styleUrls: ['./leaflets-list.component.scss']
})
export class LeafletsListComponent implements OnInit {
  leaflets;
  subscription: Subscription;
  constructor(private uploadService: UploadLeafletFileService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.getLeaflets();
    this.uploadService.leafletChanged
      .subscribe(() => {
        this.getLeaflets();
      })
  }

  async getLeaflets() {
    this.leaflets = await this.uploadService.getLeaflets();
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
