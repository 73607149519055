import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-price-promotions-item',
  templateUrl: './price-promotions-item.component.html',
  styleUrls: ['./price-promotions-item.component.scss']
})
export class PricePromotionsItemComponent implements OnInit {
  @Input() promotion;
  @Output() promotionSelected = new EventEmitter<void>();
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

  onSelected() {
    this.promotionSelected.emit();
  }

}
