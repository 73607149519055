import { Component, OnInit } from '@angular/core';
import { IdSharedService } from '../../../services/id-shared.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  constructor(private idSharedService: IdSharedService) { }

  ngOnInit() {
  }

  selectedSlide(slide) {
    console.log(slide.id);
    this.idSharedService.id = slide.id;
  }

}
