import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { ResponseHandlingService } from './response-handling.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPushNotificationModalComponent } from 'src/app/components/panel-container/confirm-push-notification-modal/confirm-push-notification-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private httpClient: HttpClient;
  constructor(private modalService: NgbModal,
              private handler: HttpBackend,
              private responseHandlingService: ResponseHandlingService) { }

  public showPushConfirmationModal(
    title: string,
    body: string,
    to: string,
    navigateUrl: string,
    btnOkText: string = 'Wyślij',
    btnCancelText: string = 'Anuluj',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmPushNotificationModalComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.to = to;
    modalRef.componentInstance.url = navigateUrl;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  public sendPushNotification(notification) {
    let notificationJSON;
    notificationJSON = 
    {
      "notification":{
        "title":notification.title,
        "body":notification.body,
        "sound":"default",
        "icon":"fcm_push_icon",
        "click_action":"FCM_PLUGIN_ACTIVITY"
      },
      "data":{
        "navigateUrlParam": notification.navigateUrlParam
      }, 
      to: '/topics/' + notification.to.replace(/\s/g, '').toLowerCase(),
      "priority":"high"
    }
    this.httpClient = new HttpClient(this.handler);
    console.log(notificationJSON);
    this.httpClient.post('https://fcm.googleapis.com/fcm/send',
                          notificationJSON,
                          { headers: new HttpHeaders(
                              { 'Authorization': 'key=AAAAWENWlj8:APA91bGhL37CzBkHwf60YnM3L2OXww7LD-vjsCh9BhDsRWMbVn1NwaEYnFMNKJd564nxRjsWRVVkRFBAOR0mEnWrwURTTVgTIOyxDrJTgMBdGlcuXA4KiTtKnapDT7txb9rE8MHecPtN',
                                'Content-Type': 'application/json' })
                          }).toPromise()
                          .then(() => {
                              this.responseHandlingService.handleData;
                          })
                          .catch(this.responseHandlingService.handleError);
    
  }
}
