import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-shops-item',
  templateUrl: './shops-item.component.html',
  styleUrls: ['./shops-item.component.scss']
})
export class ShopsItemComponent implements OnInit {
  @Input() shop;
  @Output() shopSelected = new EventEmitter<void>();
  @Input() index: number;

  constructor() { }

  ngOnInit() {
  }

  onSelected() {
    this.shopSelected.emit();
  }

}
