import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TagsArrayToStringService {

  constructor() { }

  public convert(tagsArray): string {
    const removeFirstComma = new RegExp("^, ", "gi");
    let tagsConvertedToString: string = '';
    tagsArray.forEach(function(tagElement) {
      tagsConvertedToString = tagsConvertedToString + ', '  + tagElement.tag;
    })
    tagsConvertedToString = tagsConvertedToString.replace(removeFirstComma, '');
    return tagsConvertedToString;
  }

}
