import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { NewsService } from '../news.service';
import { News } from '../news.model';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {

  editMode = false;
  newsForm: FormGroup;
  loaded: boolean = false;

  newsId:number;
  newsTitle: string;
  newsDescriptionExcerpt: string;
  newsDescription: string;
  newsTags = new FormArray([]);
  newsImagePath: string;
  newsPublishDate: string;

  dataToUpdate: IdataToUpdate = {
    id: null,
    formValue: null
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private idSharedService: IdSharedService,
              private newsService: NewsService) {
  }

  async onSubmit() {
    if (this.editMode) {
      this.dataToUpdate.id = this.newsId;
      this.dataToUpdate.formValue = this.newsForm.value;
      await this.newsService.updateNews(this.dataToUpdate);
    } else {
      await this.newsService.addNews(this.newsForm.value);
    }
    this.onCancel();    
  }


  onAddTags() {
    (<FormArray>this.newsForm.get('tags')).push(
      new FormGroup({
        'tag': new FormControl(null, Validators.required),
        })
    );
  }

  onDeleteNews(index: number) {
    (<FormArray>this.newsForm.get('tags')).removeAt(index);
  }


  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {

          this.editMode = params['id'] != null;

          if (this.editMode) {
            this.newsId = this.idSharedService.getIdSelectedItem();
            await this.newsService.getSingleNews(this.newsId).then(news => {
              this.newsTitle = news.title;
              this.newsDescriptionExcerpt = news.shortDesc;
              this.newsDescription = news.description;
              for (let tag of news.tags.split(', ')) {
                this.newsTags.push(
                  new FormGroup({
                  'tag': new FormControl(tag, Validators.required),
                  })
                );
              }
              this.newsImagePath = news.imagePath;
              this.newsPublishDate = new Date(news.publishDate).toISOString().split('T')[0];
              this.initForm();
              this.loaded = true;
            });
          } else {
            this.initForm();
            this.loaded = true;
          }
          
        }
      );
  }

  private async initForm() {
    this.newsForm = new FormGroup({
      'title': new FormControl(this.newsTitle, Validators.required),
      'descriptionExcerpt': new FormControl(this.newsDescriptionExcerpt, Validators.required),
      'description': new FormControl(this.newsDescription, Validators.required),
      'tags': this.newsTags,
      'imagePath': new FormControl(this.newsImagePath, Validators.required),
      'publishDate': new FormControl(this.newsPublishDate, Validators.required)
    });
  }
}

export interface IdataToUpdate {
  id: number;
  formValue: FormGroup;
}
