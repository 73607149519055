import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { StartupService } from './startup.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {

    private token: string;

    constructor(private http: HttpClient,
              private globals: Globals,
              private router: Router,
              private startupService: StartupService) { }

    public login(username: string, password: string) {
        return this.http.post<any>(this.globals.apiUrl + 'user/login', { username, password })
            .pipe(map(user => {
                if (user && user.token) {
                    this.assignUserToken(user.token);
                    localStorage.setItem('usernameLoggedIn', user.username);
                    localStorage.setItem('currentUserToken', user.token);
                }
                return user;
            }));
    }

    public logout() {
        this.router.navigate(['/login']);
        localStorage.removeItem('usernameLoggedIn');
        localStorage.removeItem('currentUserToken');
        this.token = null;
    }

    public getUsername(): string {
        return localStorage.getItem('usernameLoggedIn');
    }

    public isAuthenticated() {
        return this.token != null;
    }

    public assignUserToken(token) {
        this.token = token;
    }

    public canUserEditElementAuthorize(typeOfElement: string, index: number) {
        let id: number = index;
        return this.http.get<boolean>(this.globals.apiUrl + typeOfElement + '/canEdit/' + id)
                   .toPromise()
                   .then((data) => { return data })
                   .catch(() => { return false });
    }
    
    public isTokenValid(): boolean {
        if (this.startupService.isStoredTokenValid()) {
            this.token = localStorage.getItem('currentUserToken');
            return true;
        }
        return false;
    }

    public currentUserRole() {
        return this.http.get<boolean>(this.globals.apiUrl + 'user/userRole')
                    .toPromise()
                    .then((data) => {
                        return data;
                    })
                   .catch(() => { return false });
    }

    public currentUserChain() {
        return this.http.get<boolean>(this.globals.apiUrl + 'user/userChain')
                    .toPromise()
                    .then((data) => {
                        return data;
                    })
                   .catch(() => { return false });
    }
}
