import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotional-leaflet',
  templateUrl: './promotional-leaflet.component.html',
  styleUrls: ['./promotional-leaflet.component.scss']
})
export class PromotionalLeafletComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

    
}