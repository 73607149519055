import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-version-control-edit',
  templateUrl: './version-control-edit.component.html',
  styleUrls: ['./version-control-edit.component.scss']
})
export class VersionControlEditComponent implements OnInit {
  currentAndroidVersion;
  currentIOSVersion;
  importantMessage;

  dataToUpdate: IdataToUpdate = {
    type: null,
    content: null
  };

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    this.technicalDataService.getTextData('current-android-version').then(async res => {
      this.currentAndroidVersion = new FormControl(await res.message);
    });
    this.technicalDataService.getTextData('current-ios-version').then(async res => {
      this.currentIOSVersion = new FormControl(await res.message);
    });
    this.technicalDataService.getTextData('important-message').then(async res => {
      this.importantMessage = new FormControl(await res.message);
    });
  }

  async onSubmit() {
    this.dataToUpdate.type = "currentAndroidVersion";
    this.dataToUpdate.content = this.currentAndroidVersion.value;
    await this.technicalDataService.update(this.dataToUpdate);
    this.dataToUpdate.type = "currentIosVersion";
    this.dataToUpdate.content = this.currentIOSVersion.value;
    await this.technicalDataService.update(this.dataToUpdate);
    this.dataToUpdate.type = "importantMessage";
    this.dataToUpdate.content = this.importantMessage.value;
    await this.technicalDataService.update(this.dataToUpdate);
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}

export interface IdataToUpdate {
  type: string;
  content: FormControl;
}