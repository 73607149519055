import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { IdSharedService } from '../../services/id-shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-login',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup;
  userName = '';
  password = '';
  id;

  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private idSharedService: IdSharedService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.loginForm = new FormGroup({
      'userLogin': new FormControl(this.userName, Validators.required),
      'userPassword': new FormControl(this.password, Validators.required),   
    });
  }

  onSubmit() {
    this.userAuthenticationService.login(this.loginForm.value.userLogin, this.loginForm.value.userPassword)
          .subscribe(
            data => {
              this.router.navigate(['/panel']);
            },
            error => {
              alert('Błędna nazwa użytkownika lub hasło.\nKod odpowiedzi: ' + error.status + " - " + error.statusText + "." );
          });
  }
}
