import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ShopsService } from '../../../../services/shops.service';
import { IdSharedService } from '../../../../services/id-shared.service';
import { DeleteItemModalService } from '../../delete-item-modal/delete-item-modal.service';
import { CurrentElementChainService } from '../../../../services/current-element-chain.service';

@Component({
  selector: 'app-shops-detail',
  templateUrl: './shops-detail.component.html',
  styleUrls: ['./shops-detail.component.scss']
})
export class ShopsDetailComponent implements OnInit {
  shop;
  id: number;
  constructor(private shopsService: ShopsService,
              private idSharedService: IdSharedService,
              private route: ActivatedRoute,
              private router: Router,
              private deleteItemModalService: DeleteItemModalService,
              private currentElementChainService: CurrentElementChainService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.id = await this.idSharedService.getIdSelectedItem();
          if(this.id == null) return;
          this.shop = await this.shopsService.getSingleShop(this.id);
        }
      )
  }

  onEditShop() {
    this.currentElementChainService.chainName = this.shop.chain;
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteShop() {
   this.deleteItemModalService.confirm('Usuwasz sklep!', 'Czy na pewno chcesz usunąć sklep: "' + this.shop.shopName + '" ?')
    .then((confirmed) => {
      if(confirmed) {
        this.shopsService.deleteShop(this.id);
        this.shop = undefined;
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
