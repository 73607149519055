import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';
import { IdSharedService } from './id-shared.service';
import { DiscountCodes } from '../models/discountCode.model';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodesService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService,
              private idSharedService: IdSharedService) { }

  discountCodesChanged = new Subject();

  getDiscountCodes(): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'discount-codes/B')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }

  getSingleDiscountCode(index: number): Promise<DiscountCodes> {
    let id: number = index;
    return this.http.get<DiscountCodes>(this.globals.apiUrl + 'discount-codes/' + id)
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }

  updateDiscountCode(discountCodeToUpdate) {
    this.idSharedService.id = discountCodeToUpdate.formValue.id;
    return this.http.post(this.globals.apiUrl + 'discount-codes/edit', discountCodeToUpdate)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData,
                    this.discountCodesChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  deleteDiscountCode(discountCodeIdToDelete) {
      let discountCodeToDelete = { id: discountCodeIdToDelete }
      return this.http.post(this.globals.apiUrl + 'discount-codes/delete', discountCodeToDelete)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData;
                      this.discountCodesChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }

  addDiscountCode(discountCodeToAdded) {
      return this.http.post(this.globals.apiUrl + 'discount-codes', discountCodeToAdded)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData,
                      this.discountCodesChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }
}
