import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';

@Injectable({
  providedIn: 'root'
})
export class TechnicalDataService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService) { }

  getTextData(typeOfData) {
    return this.http.get(this.globals.apiUrl + 'technical-data/' + typeOfData)
      .toPromise()
      .then(this.responseHandlingService.handleData)
      .catch(this.responseHandlingService.handleError)
  }

  update(dataToUpdate) {
    return this.http.post(this.globals.apiUrl + 'technical-data/edit', dataToUpdate)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                })
                .catch(this.responseHandlingService.handleError);
  }
}
