import { Component, OnInit, Input } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploadLeafletFileService } from '../../../../services/upload-leaflet-file.service';
import { Globals } from '../../../../globals'

@Component({
  selector: 'app-pdfviewer-container',
  templateUrl: './pdfviewer-container.component.html',
  styleUrls: ['./pdfviewer-container.component.scss']
})
export class PdfviewerContainerComponent implements OnInit {
  @Input() uploadedFileName: string;
  pdfSrc: string;

  constructor(
    private uploadService: UploadLeafletFileService,
    private globals: Globals) {
      this.uploadService.onGetLeafletFileName.subscribe(filename => {
        this.pdfSrc = this.globals.hostname + filename;
        console.log(this.pdfSrc);
      });
    }

  ngOnInit() {
  }

}
