import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';
import { IdSharedService } from './id-shared.service';
import { Shops } from '../models/shops.model';

@Injectable({
  providedIn: 'root'
})
export class ShopsService {

  constructor(private http: HttpClient,
    private globals: Globals,
    private responseHandlingService: ResponseHandlingService,
    private idSharedService: IdSharedService) { }

  shopsChanged = new Subject();

  getShops(): Promise<any>{
  return this.http.get(this.globals.apiUrl + 'shops/B')
      .toPromise()
      .then(this.responseHandlingService.handleData)
      .catch(this.responseHandlingService.handleError)
  }

  getSingleShop(index: number): Promise<Shops> {
  let id: number = index;
  return this.http.get<Shops>(this.globals.apiUrl + 'shops/' + id)
      .toPromise()
      .then(this.responseHandlingService.handleData)
      .catch(this.responseHandlingService.handleError);
  }

  updateShop(shopToUpdate) {
    this.idSharedService.id = shopToUpdate.formValue.id;
    return this.http.post(this.globals.apiUrl + 'shops/edit', shopToUpdate)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData,
                    this.shopsChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  deleteShop(shopIdToDelete) {
      let shopToDelete = { id: shopIdToDelete }
      return this.http.post(this.globals.apiUrl + 'shops/delete', shopToDelete)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData;
                      this.shopsChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }

  addShop(shopToAdded) {
      return this.http.post(this.globals.apiUrl + 'shops', shopToAdded)
                  .toPromise()
                  .then(() => {
                      this.responseHandlingService.handleData,
                      this.shopsChanged.next();
                  })
                  .catch(this.responseHandlingService.handleError);
  }
}
