import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShopsService } from '../../../../services/shops.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-shops-list',
  templateUrl: './shops-list.component.html',
  styleUrls: ['./shops-list.component.scss']
})
export class ShopsListComponent implements OnInit {
  @Output() shopWasSelected = new EventEmitter<void>();
  shops;
  subscription: Subscription;

  constructor(private shopsService: ShopsService,
              private router: Router,
              private route: ActivatedRoute) {    
              }

  ngOnInit() {
    this.getShops();
    this.subscription = this.shopsService.shopsChanged
      .subscribe(() => {
        this.getShops();
      }
    )
  }
  
  async getShops() {
    this.shops = await this.shopsService.getShops();
  }

  onShopSelected(shop) {
    this.shopWasSelected.emit(shop);
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
