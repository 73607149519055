import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlingService {

  constructor() { }

  public handleData(res: any) {
    let body = res;
    return body || {};
  }

  public handleError(error: any): Promise<any> {
    alert(error.error.message || "Wystąpił nieoczekiwany błąd. Skontaktuj się z administratorem.");
    return Promise.reject(error.message || error);
  }

}
