import { Injectable } from "@angular/core";


@Injectable()
export class Globals {

  //apiUrl: string = 'http://localhost:3003/api/';
  //hostname: string = 'http://localhost:3003/';
  apiUrl: string = 'https://passa-server.borecki.usermd.net:8560/api/';
  hostname: string = 'https://passa-server.borecki.usermd.net:8560/';
}