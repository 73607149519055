import { Component, OnInit, Input, } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { News } from '../news.model';
import { NewsService } from '../news.service';
import { IdSharedService } from '../../../../services/id-shared.service';
import { DeleteItemModalService } from '../../delete-item-modal/delete-item-modal.service';
import { CurrentElementChainService } from '../../../../services/current-element-chain.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {
  news: News;
  id: number;

  constructor(private newsService: NewsService,
              private idSharedService: IdSharedService,
              private route: ActivatedRoute,
              private router: Router,
              private deleteItemModalService: DeleteItemModalService,
              private currentElementChainService: CurrentElementChainService
              ) {
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {
          this.id = this.idSharedService.getIdSelectedItem();
          if(this.id == null) return;
          this.news = await this.newsService.getSingleNews(this.id);
        }
      )
  }

  onEditNews() {
    this.currentElementChainService.chainName = this.news.chain;
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteNews() {
    this.deleteItemModalService.confirm('Usuwasz aktualność!', 'Czy na pewno chcesz usunąc aktualność: "' + this.news.title + '" ?')
    .then((confirmed) => {
      if(confirmed) {
        this.newsService.deleteNews(this.id);
        this.news = undefined;
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
