import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TutorialService } from '../../../../services/tutorial.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-tutorial-list',
  templateUrl: './tutorial-list.component.html',
  styleUrls: ['./tutorial-list.component.scss']
})
export class TutorialListComponent implements OnInit {
  @Output() tutorialItemWasSelected = new EventEmitter<void>();
  tutorialSlides;
  subscription: Subscription;

  constructor(private tutorialService: TutorialService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.getTutorialSlides();
    this.subscription = this.tutorialService.tutorialChanged
      .subscribe(() => {
        this.getTutorialSlides();
      }
    )
  }

  async getTutorialSlides() {
    this.tutorialSlides = await this.tutorialService.getSlides();
  }

  onTutorialItemSelected(slide) {
    this.tutorialItemWasSelected.emit(slide);
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
