import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CKEditorModule } from 'ng2-ckeditor';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { DiscountCodesComponent } from './components/panel-container/discount-codes/discount-codes.component';
import { PromotionalLeafletComponent } from './components/panel-container/promotional-leaflet/promotional-leaflet.component';
import { AppRoutingModule } from './app-routing.module';
import { NewsComponent } from './components/panel-container/news/news.component';
import { PricePromotionsComponent } from './components/panel-container/price-promotions/price-promotions.component';
import { LoyaltyProgramComponent } from './components/panel-container/loyalty-program/loyalty-program.component';
import { NewsService } from './components/panel-container/news/news.service';
import { NewsListComponent } from './components/panel-container/news/news-list/news-list.component';
import { NewsItemComponent } from './components/panel-container/news/news-item/news-item.component';
import { DetailStarterComponent } from './components/panel-container/detail-starter/detail-starter.component';
import { NewsDetailComponent } from './components/panel-container/news/news-detail/news-detail.component';
import { Globals } from './globals';
import { NewsEditComponent } from './components/panel-container/news/news-edit/news-edit.component';
import { DeleteItemModalComponent } from './components/panel-container/delete-item-modal/delete-item-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { PanelContainerComponent } from './components/panel-container/panel-container.component';
import { JwtInterceptor} from './helpers/jwt.interceptor';
import { AuthGuard } from './helpers/auth.guard';
import { CanEditElementGuard } from './helpers/can-edit-element.guard';
import { StartupService } from './services/startup.service';
import { CurrentElementChainService } from './services/current-element-chain.service'
import { UserAuthenticationService } from './services/user-authentication.service';
import { LeafletFormUploadComponent } from './components/panel-container/promotional-leaflet/leaflet-form-upload/leaflet-form-upload.component';
import { LeafletDetailsUploadComponent } from './components/panel-container/promotional-leaflet/leaflet-details-upload/leaflet-details-upload.component';
import { PdfviewerContainerComponent } from './components/panel-container/promotional-leaflet/pdfviewer-container/pdfviewer-container.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PricePromotionsDetailComponent } from './components/panel-container/price-promotions/price-promotions-detail/price-promotions-detail.component';
import { PricePromotionsEditComponent } from './components/panel-container/price-promotions/price-promotions-edit/price-promotions-edit.component';
import { PricePromotionsItemComponent } from './components/panel-container/price-promotions/price-promotions-item/price-promotions-item.component';
import { PricePromotionsListComponent } from './components/panel-container/price-promotions/price-promotions-list/price-promotions-list.component';
import { ChainsComponent } from './components/panel-container/chains/chains.component';
import { ChainsListComponent } from './components/panel-container/chains/chains-list/chains-list.component';
import { ChainsItemComponent } from './components/panel-container/chains/chains-item/chains-item.component';
import { ChainsEditComponent } from './components/panel-container/chains/chains-edit/chains-edit.component';
import { ChainsDetailComponent } from './components/panel-container/chains/chains-detail/chains-detail.component';
import { LeafletsListComponent } from './components/panel-container/promotional-leaflet/leaflets-list/leaflets-list.component';
import { LeafletsItemComponent } from './components/panel-container/promotional-leaflet/leaflets-item/leaflets-item.component';
import { VersionControlComponent } from './components/panel-container/version-control/version-control.component';
import { DiscountCodesListComponent } from './components/panel-container/discount-codes/discount-codes-list/discount-codes-list.component';
import { DiscountCodesItemComponent } from './components/panel-container/discount-codes/discount-codes-item/discount-codes-item.component';
import { DiscountCodesEditComponent } from './components/panel-container/discount-codes/discount-codes-edit/discount-codes-edit.component';
import { DiscountCodesDetailComponent } from './components/panel-container/discount-codes/discount-codes-detail/discount-codes-detail.component';
import { ShopsComponent } from './components/panel-container/shops/shops.component';
import { ShopsItemComponent } from './components/panel-container/shops/shops-item/shops-item.component';
import { ShopsDetailComponent } from './components/panel-container/shops/shops-detail/shops-detail.component';
import { ShopsEditComponent } from './components/panel-container/shops/shops-edit/shops-edit.component';
import { ShopsListComponent } from './components/panel-container/shops/shops-list/shops-list.component';
import { RulesComponent } from './components/panel-container/rules/rules.component';
import { PrivacyPolicyComponent } from './components/panel-container/privacy-policy/privacy-policy.component';
import { AboutAppComponent } from './components/panel-container/about-app/about-app.component';
import { AboutAppEditComponent } from './components/panel-container/about-app/about-app-edit/about-app-edit.component';
import { AboutAppDetailComponent } from './components/panel-container/about-app/about-app-detail/about-app-detail.component';
import { RulesDetailComponent } from './components/panel-container/rules/rules-detail/rules-detail.component';
import { PrivacyPolicyDetailComponent } from './components/panel-container/privacy-policy/privacy-policy-detail/privacy-policy-detail.component';
import { PrivacyPolicyEditComponent } from './components/panel-container/privacy-policy/privacy-policy-edit/privacy-policy-edit.component';
import { RulesEditComponent } from './components/panel-container/rules/rules-edit/rules-edit.component';
import { FileUploaderComponent } from './components/panel-container/file-uploader/file-uploader.component';
import { SliderComponent } from './components/panel-container/slider/slider.component';
import { SliderDetailComponent } from './components/panel-container/slider/slider-detail/slider-detail.component';
import { SliderEditComponent } from './components/panel-container/slider/slider-edit/slider-edit.component';
import { SliderItemComponent } from './components/panel-container/slider/slider-item/slider-item.component';
import { SliderListComponent } from './components/panel-container/slider/slider-list/slider-list.component';
import { VersionControlDetailComponent } from './components/panel-container/version-control/version-control-detail/version-control-detail.component';
import { VersionControlEditComponent } from './components/panel-container/version-control/version-control-edit/version-control-edit.component';
import { NextLeafletStartDateComponent } from './components/panel-container/promotional-leaflet/next-leaflet-start-date/next-leaflet-start-date.component';
import { NextLeafletStartDateEditComponent } from './components/panel-container/promotional-leaflet/next-leaflet-start-date-edit/next-leaflet-start-date-edit.component';
import { HelpComponent } from './components/panel-container/help/help.component';
import { BasicInstructionComponent } from './components/panel-container/help/basic-instruction/basic-instruction.component';
import { PushNotificationsInstructionComponent } from './components/panel-container/help/push-notifications-instruction/push-notifications-instruction.component';
import { TutorialComponent } from './components/panel-container/tutorial/tutorial.component';
import { TutorialListComponent } from './components/panel-container/tutorial/tutorial-list/tutorial-list.component';
import { TutorialItemComponent } from './components/panel-container/tutorial/tutorial-item/tutorial-item.component';
import { TutorialEditComponent } from './components/panel-container/tutorial/tutorial-edit/tutorial-edit.component';
import { TutorialDetailComponent } from './components/panel-container/tutorial/tutorial-detail/tutorial-detail.component';
import { PushNotificationComponent } from './components/panel-container/push-notification/push-notification.component';
import { ConfirmPushNotificationModalComponent } from './components/panel-container/confirm-push-notification-modal/confirm-push-notification-modal.component';

export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    DiscountCodesComponent,
    PromotionalLeafletComponent,
    NewsComponent,
    PricePromotionsComponent,
    LoyaltyProgramComponent,
    NewsListComponent,
    NewsItemComponent,
    DetailStarterComponent,
    NewsDetailComponent,
    NewsEditComponent,
    DeleteItemModalComponent,
    SignInComponent,
    PanelContainerComponent,
    LeafletFormUploadComponent,
    LeafletDetailsUploadComponent,
    PdfviewerContainerComponent,
    PricePromotionsDetailComponent,
    PricePromotionsEditComponent,
    PricePromotionsItemComponent,
    PricePromotionsListComponent,
    ChainsComponent,
    ChainsListComponent,
    ChainsItemComponent,
    ChainsEditComponent,
    ChainsDetailComponent,
    LeafletsListComponent,
    LeafletsItemComponent,
    VersionControlComponent,
    DiscountCodesListComponent,
    DiscountCodesItemComponent,
    DiscountCodesEditComponent,
    DiscountCodesDetailComponent,
    ShopsComponent,
    ShopsItemComponent,
    ShopsDetailComponent,
    ShopsEditComponent,
    ShopsListComponent,
    RulesComponent,
    PrivacyPolicyComponent,
    AboutAppComponent,
    AboutAppEditComponent,
    AboutAppDetailComponent,
    RulesDetailComponent,
    PrivacyPolicyDetailComponent,
    PrivacyPolicyEditComponent,
    RulesEditComponent,
    FileUploaderComponent,
    SliderComponent,
    SliderDetailComponent,
    SliderEditComponent,
    SliderItemComponent,
    SliderListComponent,
    VersionControlDetailComponent,
    VersionControlEditComponent,
    NextLeafletStartDateComponent,
    NextLeafletStartDateEditComponent,
    HelpComponent,
    BasicInstructionComponent,
    PushNotificationsInstructionComponent,
    TutorialComponent,
    TutorialListComponent,
    TutorialItemComponent,
    TutorialEditComponent,
    TutorialDetailComponent,
    PushNotificationComponent,
    ConfirmPushNotificationModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    //HttpModule,
    HttpClientModule,
    PdfViewerModule,
    CKEditorModule
  ],
  entryComponents: [
    DeleteItemModalComponent,
    ConfirmPushNotificationModalComponent
  ],
  providers: [NewsService,
              Globals,
              DeleteItemModalComponent,
              ConfirmPushNotificationModalComponent,
              NgbActiveModal,
              AuthGuard,
              CanEditElementGuard,
              StartupService,
              { provide: HTTP_INTERCEPTORS,
                useClass: JwtInterceptor,
                multi: true 
              },
              {
                // Provider for APP_INITIALIZER
                provide: APP_INITIALIZER,
                useFactory: startupServiceFactory,
                deps: [StartupService],
                multi: true
              },
              {
                provide: Window, 
                useValue: window
              },
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
