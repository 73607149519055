import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service'

@Component({
  selector: 'app-next-leaflet-start-date',
  templateUrl: './next-leaflet-start-date.component.html',
  styleUrls: ['./next-leaflet-start-date.component.scss']
})
export class NextLeafletStartDateComponent implements OnInit {
  nextLeafletStartDate: string;

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    this.nextLeafletStartDate = await this.technicalDataService.getTextData('next-leaflet-start-date');
    console.log(this.nextLeafletStartDate);
  }

  onEditItem() {
    this.router.navigate(['../next-leaflet-start-date-edit'], {relativeTo: this.route});
  }

}
