import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { UserAuthenticationService } from '../services/user-authentication.service';
import { IdSharedService } from '../services/id-shared.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CanEditElementGuard implements CanActivate {

    constructor(private userAuthenticationService: UserAuthenticationService,
                private idSharedService: IdSharedService
                ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const urlArray = state.url.split("/");
        let canActivateResponse = await this.userAuthenticationService.
                            canUserEditElementAuthorize(
                                urlArray[urlArray.length-3],
                                this.idSharedService.getIdSelectedItem()
                            );
        if(canActivateResponse) return true;
        else {
            alert('Nie masz uprawnień do edycji tego elementu.')
            return false;
        }
    }
}