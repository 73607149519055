import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChainsService } from '../../../../services/chains.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-chains-list',
  templateUrl: './chains-list.component.html',
  styleUrls: ['./chains-list.component.scss']
})
export class ChainsListComponent implements OnInit {
  @Output() chainWasSelected = new EventEmitter<void>();
  chains;
  subscription: Subscription;

  constructor(private chainsService: ChainsService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.getChains();
    this.subscription = this.chainsService.chainsChanged
      .subscribe(() => {
        this.getChains();
      }
    )
  }

  async getChains() {
    this.chains = await this.chainsService.getChains();
  }

  onChainSelected(chain) {
    this.chainWasSelected.emit(chain);
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

}
