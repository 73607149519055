import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IdSharedService } from '../../../../services/id-shared.service';
import { TutorialService } from '../../../../services/tutorial.service';


@Component({
  selector: 'app-tutorial-edit',
  templateUrl: './tutorial-edit.component.html',
  styleUrls: ['./tutorial-edit.component.scss']
})
export class TutorialEditComponent implements OnInit {
  editMode = false;
  tutorialSlidesForm: FormGroup;
  loaded: boolean = false;

  slideId: number;
  slideTitle: string;
  slideDescription: string;
  imagePath: string;
  publishDate: string;

  dataToUpdate: IdataToUpdate = {
    id: null,
    formValue: null
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private idSharedService: IdSharedService,
              private tutorialService: TutorialService) {      
  }

  ngOnInit() {
    this.route.params
      .subscribe(
        async (params: Params) => {

          this.editMode = params['id'] != null;

          if (this.editMode) {
            this.slideId = this.idSharedService.getIdSelectedItem();
            await this.tutorialService.getSingleSlide(this.slideId).then(tutorialSlide => {
              this.slideTitle = tutorialSlide.title;
              this.slideDescription = tutorialSlide.description;
              this.imagePath = tutorialSlide.imagePath;
              this.publishDate = new Date(tutorialSlide.publishDate).toISOString().split('T')[0];
              this.initForm();
              this.loaded = true;
            });
          } else {
            this.initForm();
            this.loaded = true;
          }
          
        }
      );
  }

  async onSubmit() {
    if (this.editMode) {
      this.dataToUpdate.id = this.slideId;
      this.dataToUpdate.formValue = this.tutorialSlidesForm.value;
      await this.tutorialService.updateSlide(this.dataToUpdate);
    } else {
      await this.tutorialService.addSlide(this.tutorialSlidesForm.value);
    }
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.tutorialSlidesForm = new FormGroup({
      'title': new FormControl(this.slideTitle, Validators.required),
      'description': new FormControl(this.slideDescription, Validators.required),
      'imagePath': new FormControl(this.imagePath, Validators.required),
      'publishDate': new FormControl(this.publishDate, Validators.required)
    });
  }

}

export interface IdataToUpdate {
  id: number;
  formValue: FormGroup;
}
