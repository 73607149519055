import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechnicalDataService } from '../../../../services/technical-data.service'

@Component({
  selector: 'app-rules-detail',
  templateUrl: './rules-detail.component.html',
  styleUrls: ['./rules-detail.component.scss']
})
export class RulesDetailComponent implements OnInit {
  rulesContent: string;

  constructor(private technicalDataService: TechnicalDataService,
    private route: ActivatedRoute,
    private router: Router) { }

    async ngOnInit() {
      this.rulesContent = await this.technicalDataService.getTextData('rules');
    }
  
    onEditItem() {
      this.router.navigate(['edit'], {relativeTo: this.route});
    }

}
