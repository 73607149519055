import { Component, OnInit } from '@angular/core';
import { News } from './news.model';
import { IdSharedService } from '../../../services/id-shared.service';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  constructor(private idSharedService: IdSharedService) { }

  ngOnInit() {

  }

  selectedNews(news) {
    this.idSharedService.id = news.id;
  }

}
